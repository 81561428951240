// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Tailwind CSS utility classes have been used for convenience */
/* Additional custom CSS if needed */

.section {
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.section .svg-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

.section .content {
  position: relative;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.section .content .heading {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  color: #fff;
}

.section .content .subheading {
  font-size: 1rem;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}

.section .content .description {
  font-size: 1rem;
  color: #f0f0f0;
  max-width: 600px;
  margin: 0 auto;
}

/* Media Queries */
@media (min-width: 768px) {
  .section .content {
    flex-direction: row;
    text-align: left;
  }

  .section .content .heading {
    font-size: 3rem;
  }

  .section .content .description {
    margin-left: 2rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/styles.css"],"names":[],"mappings":"AAAA,gEAAgE;AAChE,oCAAoC;;AAEpC;EACE,kBAAkB;EAClB,iBAAiB;EACjB,oBAAoB;AACtB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,yBAAyB;EACzB,qBAAqB;AACvB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA,kBAAkB;AAClB;EACE;IACE,mBAAmB;IACnB,gBAAgB;EAClB;;EAEA;IACE,eAAe;EACjB;;EAEA;IACE,iBAAiB;EACnB;AACF","sourcesContent":["/* Tailwind CSS utility classes have been used for convenience */\n/* Additional custom CSS if needed */\n\n.section {\n  position: relative;\n  padding-top: 5rem;\n  padding-bottom: 5rem;\n}\n\n.section .svg-background {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  overflow: hidden;\n  z-index: 0;\n}\n\n.section .content {\n  position: relative;\n  z-index: 10;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  text-align: center;\n}\n\n.section .content .heading {\n  font-size: 2.5rem;\n  font-weight: 700;\n  margin-bottom: 1rem;\n  color: #fff;\n}\n\n.section .content .subheading {\n  font-size: 1rem;\n  font-weight: 500;\n  text-transform: uppercase;\n  margin-bottom: 0.5rem;\n}\n\n.section .content .description {\n  font-size: 1rem;\n  color: #f0f0f0;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n/* Media Queries */\n@media (min-width: 768px) {\n  .section .content {\n    flex-direction: row;\n    text-align: left;\n  }\n\n  .section .content .heading {\n    font-size: 3rem;\n  }\n\n  .section .content .description {\n    margin-left: 2rem;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
