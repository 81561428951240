import React, { useState } from "react";

const CookieSettings = () => {
    const [showToast, setShowToast] = useState(false);

    const handleSavePreferences = () => {
        setShowToast(true);
        // Automatically hide the toast after 3 seconds
        setTimeout(() => setShowToast(false), 3000);
    };

    return (
        <div className="w-full h-full">
            {/* Header */}
            <div className="bg-white p-4 md:p-8 border-b shadow-md sticky top-0 z-50 p-8 md:px-24 ">
                <div className="flex items-center space-x-4">
                    <a href="/">
                        <img src="./images/back.svg" className="cursor-pointer w-6 h-6" alt="Go Back" />
                    </a>
                    <h1 className="text-2xl md:text-4xl font-semibold">Cookie Settings</h1>
                </div>
            </div>

            {/* Content */}
            <div className="p-4 md:p-8 space-y-6 p-8 md:px-24 ">
                <p className="text-base leading-relaxed">
                    Our website uses cookies. By using our website and agreeing to this policy, you consent to our use of cookies in accordance with the terms of this policy.
                </p>

                <div>
                    <h2 className="text-lg md:text-xl font-semibold mb-2">About cookies</h2>
                    <p className="text-base leading-relaxed mb-4">
                        Cookies are a kind of short-term memory for the web. They are stored in your browser and enable a site to ‘remember’ little bits of information between pages or visits. Cookies may be either “persistent” cookies or “session” cookies:
                    </p>
                    <ul className="list-disc ml-5 text-base space-y-2">
                        <li>Persistent cookies remain valid until their set expiry date unless deleted.</li>
                        <li>Session cookies expire at the end of the user session when the browser is closed.</li>
                    </ul>
                </div>

                <div>
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Cookies on our website</h2>
                    <p className="text-base leading-relaxed">
                        We use both session cookies and persistent cookies on our website.
                    </p>
                </div>

                <div>
                    <h2 className="text-lg md:text-xl font-semibold mb-2">How we use cookies</h2>
                    <p className="text-base leading-relaxed mb-4">
                        Cookies do not contain any information that personally identifies you. The cookies used on this website include strictly necessary cookies, performance cookies, and functionality cookies. We use them for:
                    </p>
                    <ul className="list-disc ml-5 text-base space-y-2">
                        <li>Recognizing your computer when you visit our website.</li>
                        <li>Retaining prospects’ email addresses and names when they sign up.</li>
                        <li>Tracking your navigation for usability improvements.</li>
                        <li>Analyzing website usage, like visitor count.</li>
                        <li>Administering the website efficiently.</li>
                    </ul>
                </div>

                {/* Cookie Preferences */}
                <div>
                    <h2 className="text-lg md:text-xl font-semibold mb-2">Manage Cookies</h2>
                    <div className="space-y-4">
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                defaultChecked
                                className="form-checkbox rounded border-gray-300 text-blue-500 focus:ring focus:ring-blue-300"
                            />
                            <span className="text-base">Allow Essential Cookies</span>
                        </label>
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox rounded border-gray-300 text-blue-500 focus:ring focus:ring-blue-300"
                            />
                            <span className="text-base">Allow Analytics Cookies</span>
                        </label>
                        <label className="flex items-center space-x-2">
                            <input
                                type="checkbox"
                                className="form-checkbox rounded border-gray-300 text-blue-500 focus:ring focus:ring-blue-300"
                            />
                            <span className="text-base">Allow Marketing Cookies</span>
                        </label>
                    </div>
                    <button
                        className="mt-6 w-full md:w-auto px-4 py-2 bg-buttonBg text-white rounded hover:bg-buttonBg hover:shadow-xl"
                        onClick={handleSavePreferences}
                    >
                        Save Preferences
                    </button>

                    {/* Toast Message */}
                    {showToast && (
                        <div className="fixed bottom-5 right-5 bg-blue-500 text-white px-4 py-2 rounded shadow-md animate-fade-in">
                            Preferences saved successfully!
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default CookieSettings;
