import React from "react";

const PrivacyPolicy = () => {
    return (
        <div className="w-full min-h-screen bg-gray-50">
            {/* Header */}
            <div className="bg-white p-6 shadow-md border-b p-8 md:px-24">
                <h1 className="text-3xl font-semibold flex items-center space-x-4">
                    <a href="/">
                        <img src="./images/back.svg" className="cursor-pointer w-6 h-6" alt="Go Back" />
                    </a>
                    <span>Privacy Policy</span>
                </h1>
            </div>

            {/* Content */}
            <div className="p-6 space-y-6 text-gray-700 p-8 md:px-24">
                <p className="text-lg">
                    This privacy notice explains what to expect from us when we handle your personal information.
                </p>

                {/* Section: Contact Details */}
                <section>
                    <h2 className="text-xl font-semibold mb-2">Contact Details</h2>
                    <p>
                        Email: <a href="mailto:support@fortiminds.com" className="text-blue-500">support@fortiminds.com</a>
                    </p>
                </section>

                {/* Section: Information We Collect */}
                <section>
                    <h2 className="text-xl font-semibold mb-2">What Information We Collect, Use, and Why</h2>
                    <p className="mb-2">We collect or use the following information to provide services and goods, including delivery:</p>
                    <ul className="list-disc ml-6 mb-4">
                        <li>Names and contact details</li>
                        <li>Website user information (e.g., user journeys and cookie tracking)</li>
                    </ul>
                    <p className="mb-2">We collect or use the following information for service updates or marketing purposes:</p>
                    <ul className="list-disc ml-6 mb-4">
                        <li>Names and contact details</li>
                        <li>Location data</li>
                        <li>Website and app user journey information</li>
                        <li>Records of consent, where appropriate</li>
                    </ul>
                </section>

                {/* Section: Lawful Bases */}
                <section>
                    <h2 className="text-xl font-semibold mb-2">Lawful Bases and Data Protection Rights</h2>
                    <p className="mb-2">Our lawful bases for collecting or using personal information to provide services and goods are:</p>
                    <ul className="list-disc ml-6 mb-4">
                        <li>
                            <strong>Consent</strong>: We have permission from you after providing all relevant information. You may withdraw your consent at any time.
                        </li>
                    </ul>
                </section>

                {/* Section: Data Sources */}
                <section>
                    <h2 className="text-xl font-semibold mb-2">Where We Get Personal Information From</h2>
                    <p>We collect information directly from you.</p>
                </section>

                {/* Section: Retention Period */}
                <section>
                    <h2 className="text-xl font-semibold mb-2">How Long We Keep Information</h2>
                    <p>
                        Your personal information will be stored until the purpose for which it was collected has been achieved. You will be notified if your data is kept longer than this period.
                    </p>
                </section>

                {/* Section: Complaints */}


                {/* Section: Last Updated */}
                <p className="text-sm text-gray-500 mt-6">Last updated: 20 November 2024</p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
