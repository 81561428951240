// src/components/CookieConsent.js
import React, { useState, useEffect } from 'react';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
    };

    const handleReject = () => {
        localStorage.setItem('cookieConsent', 'rejected');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="bg-gray-800 bottom-0 fixed flex flex-col items-start justify-between left-0 m-2 md:flex-row md:items-center md:space-x-6 md:space-y-0 px-6 py-4 right-0 space-y-4 text-white w-auto rounded-xs  z-50">
            <p className="text-sm font-secondary">
                We use cookies to enhance your experience. By continuing to visit this site, you agree to our use of cookies.{' '}
                <a href="/cookie-settings" className="underline">
                    Learn more
                </a>.
            </p>
            <div className="flex space-x-4">
                <button
                    onClick={handleReject}
                    className="bg-gray-600 text-white px-4 py-2 rounded-md hover:bg-buttonBg"
                >
                    Reject
                </button>
                <button
                    onClick={handleAccept}
                    className="bg-buttonBg text-white px-4 py-2 rounded-md hover:bg-buttonBg"
                >
                    Accept
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;
