import React from "react";

const Terms = () => {
    return (

        <div className=" w-full h-[90vh]">
            <div className=" top-0 bg-white p-8 left-0 z-50 w-full font-primary text-2xl font-medium w-full p-8 md:px-24 md:py-8 border-b">
                <h1 className="text-4xl font-semibol mb-4 font-primary flex flex-row space-x-4"> <a href="/"><img src="./images/back.svg" className="cursor-pointer" alt="" /></a> <span>Terms and Conditions</span> </h1>
            </div>

            <div className="w-full p-8 md:px-24 md:py-16">

                <p className="mb-4">
                    Welcome to <strong>FortiMinds</strong>. By using our website, you agree
                    to the following terms and conditions.
                </p>
                <h2 className="text-xl font-semibold mb-2">Use of Our Services</h2>
                <p className="mb-4">
                    You may use our services for lawful purposes only. Any misuse,
                    including unauthorized access or spamming, is strictly prohibited.
                </p>
                <h2 className="text-xl font-semibold mb-2">Intellectual Property</h2>
                <p className="mb-4">
                    All content on this website, including text, images, and logos, is the
                    property of FortiMinds and protected by copyright laws.
                </p>
                <h2 className="text-xl font-semibold mb-2">Limitation of Liability</h2>
                <p className="mb-4">
                    We are not liable for any damages arising from the use of our website or
                    services, including indirect or incidental damages.
                </p>
                <h2 className="text-xl font-semibold mb-2">Changes to Terms</h2>
                <p className="mb-4">
                    We reserve the right to modify these terms at any time. Please review
                    them periodically for updates.
                </p>
                <p className="text-sm text-gray-500">
                    If you have questions, contact us at{" "}
                    <a href="mailto:support@fortiminds.com" className="text-blue-500">
                        support@fortiminds.com
                    </a>
                    .
                </p>
            </div>
        </div>

    );
};

export default Terms;
